<template>
  <div>
    <v-file-input label="File input"></v-file-input>
    <FilterWin />
  </div>
</template>

<script>
import FilterWin from "../components/Filter.vue";

export default {
  name: "Home",
  components: {
    FilterWin,
  },
};
</script>
